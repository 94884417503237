import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { OutstandingInput, } from "src/app/models/outstandingInput";
import { FileUploadTaskService } from "src/app/services/file-upload-task/file-upload-task.service";
import { FileUploadTaskDto } from "src/app/web-api-client";
import * as XLSX from 'xlsx';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { UserService } from "src/app/services/user.service";
import { UserRoles } from "src/app/constants/user-roles";
import { CommentService } from "src/app/services/comment.service";

@Component({
  selector: "app-finalized-accounts-detail-page",
  templateUrl: "./finalized-accounts-detail-page.component.html",
  styleUrls: ["./finalized-accounts-detail-page.component.scss"],
})
export class FinalizedAccountsDetailPageComponent implements OnInit {
  // hold the taskId
  taskId: string;
  clientId: string;
  clientName: string;
  // hold the list of outstanding inputs
  outstandingInputs: OutstandingInput[] = [];
  outstandingInputsForExcel: OutstandingInput[] = [];
  outstandingInputsToDisplay: OutstandingInput[] = [];

  // input for pagination
  @Input() page: number = 1;
  @Input() pageSize: number = 20;
  @Input() onOverview: boolean = false;

  hideShowMoreBtn: boolean = false;

  // loading indicator
  loadingInformativeTask: boolean = false;

  fileName = 'ExcelSheet.xlsx';
  networkMemberId: string;
  networkMemberName: string;
  isNetworkMember: boolean;

  gfg = false;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp
  // constructor
  constructor(
    private activatedroute: ActivatedRoute,
    public fileUploadTaskService: FileUploadTaskService,
    private titleService: Title,
    private userService: UserService,
    private commentService: CommentService
  ) { }

  // oninit methodW
  async ngOnInit() {
    // take the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    this.clientId = this.activatedroute.snapshot.paramMap.get("clientId");
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    // if there is no active task
    let task = await this.fileUploadTaskService.getFileUploadTaskByIdAndSetActive(this.taskId);
    let networkMember = task.networkMember;
    this.clientName = task.title;
    this.networkMemberId = networkMember.id;
    this.networkMemberName = networkMember.accountName;

    // set the title
    this.titleService.setTitle(this.fileUploadTaskService.activeFileUploadTask.title + " | Insurope");
    this.initializeMetadatas(this.fileUploadTaskService.activeFileUploadTask)
    // get the metadata of the active task
    this.getMetaData(1, 20);
  }

  getMetaData(page: number, pageSize: number) {

    if (this.outstandingInputs.length <= this.pageSize) {
      this.hideShowMoreBtn = true;
    }

    this.outstandingInputsForExcel = this.outstandingInputs;

    // paginate the outstanding inputs
    this.outstandingInputsToDisplay = this.outstandingInputs.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );


  }

  initializeMetadatas(task: FileUploadTaskDto) {
    // parse the metadata
    const parsed = JSON.parse(task.metaData);

    // save the outstanding inputs
    this.outstandingInputs = parsed.ApprovalQuestions;

    // filter on client id
    if (this.clientId) {
      this.outstandingInputs = this.outstandingInputs.filter(c => c.ClientId == this.clientId);
    }
  }

  exportexcel(): void {

    //select only the columns we want
    const selectedColumns = this.outstandingInputsForExcel.map(item => {
      return {
        "Client name": item.ClientName,
        "Country": item.CountryName,
        "Pooling system": item.PoolingSystem,
        "Due date": item.DueDate,
        "Total input still outstanding for this client(also input from other insurers)": item.XRReqCount
      };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedColumns);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileUploadTaskService.activeFileUploadTask.networkMember.accountName + ".xlsx");
  }

   loadMore() {
    this.getMetaData(this.page, (this.pageSize += 20));
  }

  addClientNameToTags(clientName: string){
    this.commentService.setClientTagValue.next(clientName);
  }
}
