import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralTaskClient, GeneralTaskDto, SortingElement } from '../web-api-client';
import { FilterParameter } from '../models/FilterPrameter';

@Injectable({
  providedIn: 'root'
})

export class InformativeService {
  informatives: GeneralTaskDto[] = [];
  activeTask: GeneralTaskDto;
  constructor(private generalTaskClient: GeneralTaskClient, private router: Router) { }

  //network Member
  async getMyOrganization(filterParameter: FilterParameter, sortingFields: SortingElement[], page: number, pageSize: number): Promise<GeneralTaskDto[]> {
    return this.generalTaskClient.getMyOrganization(filterParameter.Id,
      filterParameter.SourceId,
      filterParameter.Title,
      filterParameter.SubTitle,
      filterParameter.DisplayDate,
      filterParameter.ProcessId,
      filterParameter.NetworkMemberId,
      filterParameter.ClientId,
      filterParameter.Country,
      filterParameter.Status,
      filterParameter.ClientName,
      filterParameter.NetworkMemberIds,
      filterParameter.SubscriberId,
      filterParameter.SearchDateLimit,
      filterParameter.OverdueDate,
      filterParameter.CreatedDate,
      filterParameter.PayTo,
      sortingFields,
      page,
      pageSize).toPromise();
  }

  async getMyOrganizationPaginated(filterParameter: FilterParameter, sortingFields: SortingElement[], page: number, pageSize: number) {
    this.informatives = await this.getMyOrganization(filterParameter, sortingFields, page, pageSize);
    this.informatives = this.informatives.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return this.informatives;
  }

  async getTasks(filterParameter: FilterParameter, sortingFields: SortingElement[], page: number, pageSize: number): Promise<GeneralTaskDto[]> {
    return this.generalTaskClient.getTasks(filterParameter.Id,
      filterParameter.SourceId,
      filterParameter.Title,
      filterParameter.SubTitle,
      filterParameter.DisplayDate,
      filterParameter.ProcessId,
      filterParameter.NetworkMemberId,
      filterParameter.ClientId,
      filterParameter.Country,
      filterParameter.Status,
      filterParameter.ClientName,
      filterParameter.NetworkMemberIds,
      filterParameter.SubscriberId,
      filterParameter.SearchDateLimit,
      filterParameter.OverdueDate,
      filterParameter.CreatedDate,
      filterParameter.PayTo,
      sortingFields,
      page,
      pageSize).toPromise();
  }

  async getInformative(id: string): Promise<GeneralTaskDto> {
    try {
      return this.generalTaskClient.getTasksById(id).toPromise();
    }
    catch (error) {
      if (error.status === 404) {
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    }
  }

  async downloadFile(taskId: string, fileId: string, systemAttachment: boolean) {
    return await this.generalTaskClient.downloadFile(taskId, fileId, systemAttachment).toPromise();
  }

  async getSubscribersEmails(taskId: string) {
    return this.generalTaskClient.getTaskSubscribersByTaskId(taskId).toPromise();
  }

  async changeTaskStatus(id, status) {
    return await this.generalTaskClient.changeTaskStatus(id, status).toPromise()
  }

  async downloadFileFromPath(taskId: string, filePath: string) {
    return await this.generalTaskClient.downloadFileFromPath(taskId, filePath).toPromise();
  }
}
