import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformativeService } from '../../../../services/informative.service';
import { Location } from '@angular/common';
import { AttachmentDTO, GeneralTaskDto } from 'src/app/web-api-client';
import { UserService } from 'src/app/services/user.service';
import { UserRoles } from 'src/app/constants/user-roles';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common/common.service';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CommentService } from 'src/app/services/comment.service';
import { ProcessIds } from 'src/app/constants/processIds';

@Component({
  selector: 'app-informative-detail',
  templateUrl: './informative-detail.component.html',
  styleUrls: ['./informative-detail.component.scss']
})
export class InformativeDetailComponent implements OnInit {
  dateTime = new Date()
  @Input() displaySubTitle: boolean = true;
  @Input() process: string;
  commentAttachments: AttachmentDTO[] = [];
  informative: GeneralTaskDto;
  taskId: string;
  statusText: string = "";
  isNetworkMember: boolean;
  loadingTask: boolean = false;
  gfg = false;

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp
  constructor(public informativeService: InformativeService, private activatedroute: ActivatedRoute,
    public location: Location, private userService: UserService, private titleService: Title, private commonService: CommonService, private commentService: CommentService,) {
  }

  async ngOnInit() {
    // loading started
    this.loadingTask = true;
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember)
    await this.getInformative();
    this.statusText = this.commonService.getTaskStatusByNumber(this.informative.status.value);
    this.getCommentAttachments();
    // loading finished
    this.loadingTask = false;

    // set the title
    this.titleService.setTitle(this.informative.title + " | Insurope");
    var newDueDate = this.informative.overdueDate;
    this.informative.displayDate = newDueDate;
  }

  async getInformative() {
    this.informative = await this.informativeService.getInformative(this.taskId);
  }
  async getCommentAttachments() {
    this.commentAttachments = await this.commentService.getAllAttachments(this.taskId);
  }

  getPayToStatus() {
    if (this.informative?.payTo == 2) {
      if (this.informative?.payStartedDate) {
        const formattedDate = new Date(this.informative.payStartedDate).toLocaleDateString();
        return { text: "Confirmation received for local payment on: " + formattedDate + ". Ok to continue with the payment.", class: 'text-success' };
      } else {
        if (this.informative?.processId != ProcessIds.multipoolPayments) { return { text: "! Don't pay till you have received confirmation !", class: 'text-danger' }; }
      }
    }
    return null;
  }
}
