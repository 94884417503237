import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UserRoles } from 'src/app/constants/user-roles';

import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { CommonService } from 'src/app/services/common/common.service';
import { UserService } from 'src/app/services/user.service';
import { AttachmentDTO, BooleanTaskDto } from 'src/app/web-api-client';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CommentService } from 'src/app/services/comment.service';
import { DocumentService } from 'src/app/services/document.service';
import { FileParameter } from 'src/app/web-api-client';
@Component({
  selector: 'app-boolean-task-detail',
  templateUrl: './boolean-task-detail.component.html',
  styleUrls: ['./boolean-task-detail.component.scss']
})
export class BooleanTaskDetailComponent implements OnInit {
  dateTime = new Date()
  // hold the task id
  taskId: string;
  attachments: FileParameter[] = [];
  // hold the task
  task: BooleanTaskDto;
  status: string;
  process: string;
  commentAttachments: AttachmentDTO [] = [];
  // fa icons
  faCheck = faCheck;
  faTimes = faTimes;
  isNetworkMember: boolean;

  // loading indicator
  loadingTask: boolean = false;
  displayTaskAction: boolean = true;

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp
  gfg = false;
  // constructor
  constructor(
    private activatedroute: ActivatedRoute,
    public booleanTaskService: BooleanTaskService,
    public location: Location,
    private userService: UserService,
    private titleService: Title,
    public commonService: CommonService,
    private commentService: CommentService,
    private documentService: DocumentService,
    private datePipe: DatePipe
  ) { }

  // oninit method
  async ngOnInit() {

    // loading started
    this.loadingTask = true;

    // get the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    // determine if the user is a network member
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);

    // if there is no active task
    if (this.booleanTaskService.activeBooleanTask == null) {
      // get the task by id
      this.task = await this.booleanTaskService.getBooleanTaskByIdAndSetActive(this.taskId);
      this.status = this.commonService.getTaskStatusByNumber(this.task.status.value,true);
    }

    this.displayTaskAction = await this.commonService.displayTaskAction(this.task);

    this.process = this.activatedroute.snapshot.paramMap.get("process");
    this.getCommentAttachments();
    // loading finished
    this.loadingTask = false;
    
    // set the title
    this.titleService.setTitle(this.task.title + " | Insurope");
  }

  // method to approve the task
  async approve() {
    // change the status
    if (await this.booleanTaskService.changeTaskBooleanStatus(this.taskId, true) && this.displayTaskAction) {
      this.task.value = true;
      let fileList : FileParameter[] =[] ;
      
      for(let i = 0; i< this.task.adminDbAttachments.length;i++){
        var file =  await this.documentService.getAdminDbDocument(this.task.adminDbAttachments[i], [this.task.networkMemberId]);
        fileList.push({ data: new Blob([file.data]), fileName: file.fileName })
      }
      
      await this.commentService.addComment(this.taskId, "<b>The task has been approved</b>",fileList,null,this.process,this.task.title,this.task.networkMember.accountName,null,[])
    }
  }

  // method to reject the task
  async reject() {
    // change the status
    if (await this.booleanTaskService.changeTaskBooleanStatus(this.taskId, false)) {
      this.task.value = false;
    }
  }
  
  async getCommentAttachments(){
    this.commentAttachments = await this.commentService.getAllAttachments(this.taskId);
  }

  getDueDate(booleanTask:BooleanTaskDto){
    return booleanTask.overdueDate == undefined || booleanTask.overdueDate.getFullYear() == 1  ? "/" : this.datePipe.transform( booleanTask.overdueDate) ;
  }

  getApprovalDate(booleanTask:BooleanTaskDto){  
    return booleanTask.approvalDate == undefined || booleanTask.approvalDate.getFullYear() == 1 ? "Not approved yet" : this.datePipe.transform( booleanTask.approvalDate);
  }

  formatdate(dateYear: Date) {
    var year = dateYear.getFullYear();
    if (year === 1) {
      return "/"; 
    }
    return year; 
  }

  getMetaData(task: BooleanTaskDto) {
    if(task.sourceId.startsWith("SA")){
      const parsed = JSON.parse(task.metaData);
      const approvalQuestions = parsed.ApprovalQuestions;

      if(approvalQuestions.length > 0){
        const periodEndDate = Date.parse(approvalQuestions[0]?.PeriodEndDate);

        if(!isNaN(periodEndDate)){
          return this.formatdate(new Date(periodEndDate));
        }
        return "";
        
      }
      else {
        return ""; 
      }      
    }

  }
}
