<app-file-upload-task-detail [process]="'account-input'"></app-file-upload-task-detail>

<section class="card mt-3 mb-3 account-input-list" *ngIf="outstandingInputs.length > 0">

  <div class="card-body">

    <div style="display: inline-block; height: 100%;
    width: 100%;" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg" aria-controls="table">
      <div class="expand"><fa-icon [icon]="this.gfg ? this.faChevronDown : this.faChevronUp"></fa-icon></div>
    </div>
    <button type="button" class="btn btn-primary mb-2" style="display: inline; float:left;"
      (click)="exportexcel()">Export to excel</button>
    <table class="table table-borderless" id="excel-table" #collapse="ngbCollapse" [(ngbCollapse)]="gfg">

      <thead>
        <tr class="table-head">
          <th>Client name</th>
          <th>Country</th>
          <th>Pooling system</th>
          <th>Due date</th>
          <th>XR / PR</th>
          <th>Approval Comment</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let outstandingInput of outstandingInputsToDisplay">
          <td class="col-cell" data-label="Client name">
            <div class="info-container">
              <span><strong>{{ outstandingInput.ClientName }}</strong></span>
              <span>{{ outstandingInput.SubsidiaryName }}</span>
            </div>
          </td>
          <td data-label="Country">{{ outstandingInput.CountryName }}</td>
          <td data-label="Pooling system">{{
            outstandingInput.PoolingSystem
            }}</td>
          <td data-label="Due date">{{ outstandingInput.DueDate | date: 'longDate' }}</td>
          <td data-label="Total input still outstanding for this client">
            <ng-container *ngIf="outstandingInput.IsXrcolumn && outstandingInput.IsPrcolumn">
              XR & PR
            </ng-container>
            <ng-container *ngIf="outstandingInput.IsPrcolumn && !outstandingInput.IsXrcolumn">
              PR
            </ng-container>
            <ng-container *ngIf="outstandingInput.IsXrcolumn && !outstandingInput.IsPrcolumn">
              XR
            </ng-container>
          </td>
          <td data-label="Approval Comment" style="white-space:nowrap;">
            <div class="row"><span class="col-md">{{ outstandingInput.ApprovalLetter}}</span>
              <!-- <button type="button" class="btn btn-primary mb-2 col-md-3"
                (click)="addClientNameToTags(outstandingInput.ClientName)">Add tag</button> -->
            </div>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</section>

<div class="d-flex justify-content-center">
  <button type="button" class="btn btn-primary mt-3" *ngIf="!onOverview && !hideShowMoreBtn" (click)="loadMore()">
    <span *ngIf="loadingInformativeTask">Loading ...</span>
    <span *ngIf="!loadingInformativeTask">Load more</span>
  </button>
</div>

<app-comment-list [process]="'account-input'" [taskId]="taskId" [networkMemberId]="networkMemberId"
  [networkMemberName]="networkMemberName" [clientName]="clientName" [service]="fileUploadTaskService" [status]="status"
  [country]="this.outstandingInputs[0]?.CountryName" [isTagSystemApplicable]="false"></app-comment-list>