import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalQuestion } from 'src/app/models/approvalQuestion';
import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { BooleanTaskDto, TasksStatus } from 'src/app/web-api-client';
import * as XLSX from 'xlsx';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CommentService } from "src/app/services/comment.service";

@Component({
  selector: 'app-approvals-detail-page',
  templateUrl: './approvals-detail-page.component.html',
  styleUrls: ['./approvals-detail-page.component.scss']
})

export class ApprovalsDetailPageComponent implements OnInit {
  @Input() page: number = 1;
  @Input() pageSize: number = 20;
  @Input() onOverview: boolean = false;

  hideShowMoreBtn: boolean = false;

  // hold the task id
  taskId: string;
  task: BooleanTaskDto;
  // hold the list of approval questions
  approvalQuestions: ApprovalQuestion[] = [];
  clientName: string;
  // loading indicator
  loadingTask: boolean = false;
  country: string;
  gfg = false;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp
  networkMemberId: string;
  networkMemberName: string;
  status: TasksStatus;
  isMuTask: boolean = false;
  clientNameList: string[] = [];
  processId: string;
  constructor(private activatedroute: ActivatedRoute,
    public booleanTaskService: BooleanTaskService,
    private router: Router,
    private commentService: CommentService) { }

  // oninit method
  async ngOnInit() {

    // loading started
    this.loadingTask = true;

    // get the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");

    // reset the active task
    this.booleanTaskService.activeBooleanTask = null;

    try {
      // get the task by id
      this.task = (await this.booleanTaskService.getBooleanTaskByIdAndSetActive(this.taskId));
      this.isMuTask = this.task.processId == "approval-mu";
      this.clientName = this.task?.title;
      let networkMembers = this.task.networkMember;
      this.networkMemberId = networkMembers.id;
      this.networkMemberName = networkMembers.accountName;
      this.status = this.task.status.value;
      this.processId = this.task.processId;
    }
    catch (error) {
      if (error.status === 404) {
        // if the illustration is not found, redirect to the 404 page
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    }

    // loading finished
    this.loadingTask = false;

    // get the metadata of the active task
    this.getMetaData(this.booleanTaskService.activeBooleanTask, 1, 20);
  }

  getMetaData(task: BooleanTaskDto, page: number, pageSize: number) {
    // parse the metadata
    const parsed = JSON.parse(task.metaData);
    // save the approval questions
    this.approvalQuestions = parsed.ApprovalQuestions;
    this.clientNameList = this.approvalQuestions.map(x => x.ClientName);
    if (this.approvalQuestions.length <= this.pageSize) {
      this.hideShowMoreBtn = true;
    }

    this.approvalQuestions.forEach(question => {
      question.ApprovalLetter = question.ApprovalLetter.replace(/\r\n  \?/g, '<br>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp; ').replace(/\r\n/g, '<br>');
    });

    // sort the approval questions by client name
    this.approvalQuestions = this.approvalQuestions.sort((a, b) => a.ClientName.toLowerCase() <= b.ClientName.toLowerCase() ? -1 : 1);
    // paginate the approval questions
    this.approvalQuestions = this.approvalQuestions.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.booleanTaskService.activeBooleanTask.networkMember.accountName + ".xlsx");
  }

  loadMore() {
    // get the metadata of the active task
    this.getMetaData(this.booleanTaskService.activeBooleanTask, this.page, this.pageSize += 20);
  }

  addClientNameToTags(clientName: string) {
    this.commentService.setClientTagValue.next(clientName);
  }

  loadAll() {
    // get the metadata of the active task
    this.getMetaData(this.booleanTaskService.activeBooleanTask, 1, 300);
    this.hideShowMoreBtn = true;
  }
}
