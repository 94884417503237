import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformativeService } from '../../../../services/informative.service';
import { Location } from '@angular/common';
import { AttachmentDTO, CommentDto, GeneralTaskDto } from 'src/app/web-api-client';
import { UserService } from 'src/app/services/user.service';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common/common.service';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CommentService } from 'src/app/services/comment.service';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { ProcessIds } from 'src/app/models/enums/processIds';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-informative-detail',
  templateUrl: './informative-detail.component.html',
  styleUrls: ['./informative-detail.component.scss']
})
export class InformativeDetailComponent implements OnInit {
  dateTime = new Date()
  @Input() displaySubTitle: boolean = true;
  @Input() process: string;
  commentAttachments: AttachmentDTO[] = [];
  informative: GeneralTaskDto;
  taskId: string;
  statusText: string = "";
  isNetworkMember: boolean;
  loadingTask: boolean = false;
  gfg = false;
  comments: CommentDto[] = [];

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp

  deletedCommentEventSubscription: Subscription;
  commentAttachmentAddedEventSubscription: Subscription;

  constructor(public informativeService: InformativeService, private activatedroute: ActivatedRoute,
    public location: Location, private userService: UserService, private titleService: Title, private commonService: CommonService, private commentService: CommentService,) {
  }

  async ngOnInit() {
    this.loadingTask = true;

    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember)

    if (this.informativeService.activeTask == null) {
      this.informativeService.activeTask = await this.informativeService.getInformative(this.taskId);
    }

    this.informative = this.informativeService.activeTask;
    this.statusText = this.commonService.getTaskStatusByNumber(this.informative.status.value);

    this.comments = this.commentService.discussion?.comments?.filter(x => x.isDeleted == false);
    this.commentAttachments = this.getCommentAttachments(this.comments);

    this.titleService.setTitle(this.informative.title + " | Insurope");
    var newDueDate = this.informative.overdueDate;
    this.informative.displayDate = newDueDate;

    this.deletedCommentEventSubscription = this.commentService.deleteCommentEvent.subscribe((commentId) => { this.filterOutDeletedAttachments(commentId) });
    this.commentAttachmentAddedEventSubscription = this.commentService.addCommentAttachmentEvent.subscribe((attachment) => { this.updateAttachmentsList(attachment) });

    this.loadingTask = false;
  }

  getCommentAttachments(comments: CommentDto[]) {
    var attachments = comments.map(x => x.attachments).flat();
    var sortedAttachemnts = attachments?.sort((a, b) => a.fileDate > b.fileDate ? -1 : 1);
    return sortedAttachemnts;
  }

  getPayToStatus() {
    if (this.informative?.payTo == 2) {
      if (this.informative?.payStartedDate) {
        const formattedDate = new Date(this.informative.payStartedDate).toLocaleDateString();
        return { text: "Confirmation received for local payment on: " + formattedDate + ". Ok to continue with the payment.", class: 'text-success' };
      } else {
        if (this.informative?.processId != ProcessIds.multipoolPayments) { return { text: "! Don't pay till you have received confirmation !", class: 'text-danger' }; }
      }
    }
    return null;
  }

  filterOutDeletedAttachments(commentId: string) {
    var filteredComments = this.comments.filter(x => x.id != commentId);
    var attachments = this.getCommentAttachments(filteredComments);
    this.commentAttachments = attachments;
  }

  updateAttachmentsList(attachment: AttachmentDTO[]) {
    this.commentAttachments.push(...attachment);
  }

  ngOnDestroy() {
    this.deletedCommentEventSubscription.unsubscribe();
    this.commentAttachmentAddedEventSubscription.unsubscribe();
  }
}
