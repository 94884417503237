
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, NgZone } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Observable, OperatorFunction, Subject, Subscription, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { BooleanTaskDto, FileParameter } from 'src/app/web-api-client';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DocumentService } from 'src/app/services/document.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CommentService } from 'src/app/services/comment.service';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})

export class CommentDetailComponent implements OnInit {
  @ViewChild('commentForm') commentForm: NgForm;
  //@ViewChild('autosize') autosize: CdkTextareaAutosize;
  //@ViewChild('commentField', { static: true }) commentField: ElementRef;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('quillEditor', { static: false }) quillEditor: QuillEditorComponent | undefined;

  @Input() isTagSystemApplicable: boolean = false;
  @Input() replyTagsEvent: Observable<string[]> = new Observable<string[]>();
  @Input() approval: BooleanTaskDto;

  //Emit attachments and content of a comment
  @Output() commentEvent = new EventEmitter<{ content: string, attachments?: FileParameter[], commentTags: string[] }>();

  accountFile: FileParameter;
  showError = false;
  showAdminDbError = false;
  attachments: FileParameter[] = [];
  MAX_FILES_SIZE = 30000000;
  valid: boolean = false;
  text: string = "";
  htmlContent = "";
  error: string;
  dragAreaClass: string;
  draggedFiles: any;
  _ngZone: NgZone;
  verifyText: string = ""
  templateForm: FormGroup;
  commentTags: string[] = []
  model: any;
  tagsToAdd: string[] = []
  faCircleXmark = faCircleXmark;
  taskId: string = this.activatedroute.snapshot.paramMap.get("id");
  allDiscussionTags: string[];
  defaultTags: string[];
  replyTagsSubscription: Subscription;

  constructor(public documentService: DocumentService, private commentService: CommentService, private activatedroute: ActivatedRoute) {
    this.templateForm = new FormGroup({
      textEditor: new FormControl(''),
    });
  }

  allTags: string[];
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  async ngOnInit() {
    this.allDiscussionTags = (await this.commentService.getDiscussionTags(this.taskId, "")).map(({ tagName }) => tagName);
    this.replyTagsSubscription = this.replyTagsEvent.subscribe((tags) => this.getReplyTags(tags));

    this.defaultTags = (await (this.commentService.getDefaultTags())).map(({ tagName }) => tagName)
    this.allTags = this.allDiscussionTags.concat(this.defaultTags);
    this.getClientTag()
    this.templateForm.valueChanges.subscribe(x => {
      this.valid = this.isValid()
    });
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === '' ? this.allTags : this.allTags.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10),
      ),
    );
  };

  getClientTag() {
    this.commentService.getClientTagValue.subscribe((value) => {
      if (value != undefined) {
        this.commentTags.push(value)
      }
    });
  }

  getReplyTags(tags: string[]) {
    tags.forEach(element => {
      if (!this.commentTags.includes(element)) {
        this.commentTags.push(element)
      }
    });

    // Accessing the native DOM element containing the Quill editor
    const editorElement = this.quillEditor?.elementRef?.nativeElement;

    if (editorElement) {
      editorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      console.error('Unable to scroll. Quill editor native element not found.');
    }
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: File[] = event.dataTransfer.files;
      this.fileUploaded({ isUploaded: true, message: "", files: files });
    }
  }

  fileUploaded(response: { isUploaded: boolean, message: string, files?: File[] }) {
    var filesSize = 0;
    if (response.isUploaded) {
      // loop through the files
      for (let i = 0; i < response.files.length; i++) {
        // add the file to the list
        filesSize += response.files[i].size;

        const decodedFileName = decodeURIComponent(response.files[i].name);
        const fileNameParts = decodedFileName.split('.');
        const extension = fileNameParts.pop();
        const cleanedFileName = fileNameParts.join('.').replace(/[^a-zA-Z0-9]+/g, ' ');

        const newFileName = cleanedFileName + '.' + extension;

        this.attachments.push({ data: new Blob([response.files[i]]), fileName: newFileName });

        if (filesSize >= this.MAX_FILES_SIZE) {
          this.removeFile(this.attachments.length - 1);
          filesSize -= response.files[i].size;
          this.showFilesError();
        }
      }
      this.valid = this.isValid();
    } else {
      console.error(response.message);
    }
  }

  showFilesError() {
    this.showError = true;
    setTimeout(() => {
      this.showError = false
    }, 5000)
  }

  removeFile(index: number) {
    // remove the file
    this.attachments.splice(index, 1);
    this.valid = this.isValid();;
  }

  onClickSubmit() {
    this.commentEvent.emit({ content: this.templateForm.value.textEditor, attachments: this.attachments, commentTags: this.commentTags });
    this.attachments = [];
    this.templateForm.reset();
    this.commentTags = []
    this.valid = false;

  }

  addTag(tag: string) {
    if (this.commentTags.find(x => x == tag) == null) {
      this.commentTags.push(tag)
    }
    this.model = ''
  }

  deleteTag(index: number) {
    this.commentTags.splice(index, 1);
  }

  // triggerResize() {
  //   // Wait for changes to be applied, then trigger textarea resize.
  //   this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  // }

  async getAccountFile() {
    try {
      var fileResponse = await this.documentService.getAdminDbDocument(this.approval.adminDbAttachments[0], [this.approval.networkMemberId]);
      this.attachments.push({ data: new Blob([fileResponse.data]), fileName: fileResponse.fileName })
      this.valid = Boolean((this.attachments.length > 0 || this.text != "") || this.commentForm.valid);
    } catch (e) {
      this.showAdminDbError = true;
      setTimeout(() => {
        this.showAdminDbError = false
      }, 5000)
    }

  }

  isValid() {
    return Boolean((this.attachments.length > 0 || !(this.templateForm.value.textEditor == null || this.templateForm.value.textEditor == "")) && this.templateForm.valid);
  }

  ngOnDestroy() {
    this.replyTagsSubscription.unsubscribe();
  }
}
