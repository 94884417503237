import { Injectable } from '@angular/core';
import { CommentDto, CommentsClient, DiscussionDto, FileParameter, TaskSubscribersDto, TaskUserSusbscriptionDto } from '../web-api-client';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  //BUGZ : To communicate between components, I'm using observers (clientTagValue when we are clicking on the "add to discussion" button, replyTags when "reply" button )
  setClientTagValue = new Subject<string>();
  getClientTagValue = this.setClientTagValue.asObservable();
  setReplyTagsList = new Subject<string[]>();
  getReplyTagsList = this.setReplyTagsList.asObservable();
  discussion: DiscussionDto;
  comments: CommentDto[] = []
  taskSubscribers: TaskSubscribersDto
  constructor(private commentsClient: CommentsClient) {
  }

  async getDiscussionByTaskId(taskId: string) {
    this.discussion = await this.commentsClient.get(taskId).toPromise();
    this.comments = this.discussion.comments.reverse();
    return this.discussion;
  }

  async getTaskSubescribersByDiscussionId(discussionId: string) {
    return await this.commentsClient.getDiscussionTaskSubscribers(discussionId).toPromise();
  }

  async getIllustrationTaskSubscriber(discussionId: string, clientSubscribers: TaskUserSusbscriptionDto[], insuropeSubscribers: TaskUserSusbscriptionDto[], networkMemberSubscribers: TaskUserSusbscriptionDto[]) {
    return await this.commentsClient.getIllustrationTaskSubscribers(discussionId, clientSubscribers, insuropeSubscribers, networkMemberSubscribers).toPromise();
  }

  async addComment(taskId: string, content: string, attachments: FileParameter[], customSubscriberProvider: string, processName: string, clientName: string, networkMemberName: string, country: string, tags: string[]) {
    let comment = await this.commentsClient.create(taskId, customSubscriberProvider, processName, clientName, networkMemberName, country, attachments, content, tags).toPromise()
    if (comment) {
      this.taskSubscribers = await this.addCurrentUserToSubscriber(taskId);
    }

    this.comments.splice(0, 0, comment);
    return comment;
  }

  async downloadFile(taskId: string, discussionId: string, fileId: string) {
    return await this.commentsClient.downloadFile(taskId, discussionId, fileId).toPromise();
  }

  async deleteComment(taskId: string, commentId: string) {
    await this.commentsClient.delete(taskId, commentId).toPromise();
    var index = this.comments.findIndex(x => x.id == commentId);
    this.comments.splice(index, 1);
  }

  async editDiscussion(taskId: string, lastExportedDate: Date) {
    return await this.commentsClient.update(taskId, lastExportedDate).toPromise();
  }

  async addCurrentUserToSubscriber(taskId: string) {
    return await this.commentsClient.addCurrentUserToSubscribers(taskId).toPromise();
  }

  async addSubscriber(taskId: string, subscriberId: string) {
    return await this.commentsClient.addSubscriber(taskId, subscriberId).toPromise();
  }

  async deleteSubscriber(taskId: string, subscriberId: string) {
    return await this.commentsClient.deleteSubscriber(taskId, subscriberId).toPromise();
  }

  async getAllAttachments(taskId: string) {
    return await this.commentsClient.getAllAttachments(taskId).toPromise();
  }

  async addCommentTag(tagNames: string[], taskId: string, commentId: string) {
    return await this.commentsClient.addCommentTags(tagNames, taskId, commentId).toPromise();
  }

  async getCommentTags(taskId: string, commentId: string) {
    return await this.commentsClient.getCommentTags(taskId, commentId).toPromise();
  }

  async deleteCommentTags(tagId: string, taskId: string, commentId: string) {
    return await this.commentsClient.deleteCommentTags(tagId, taskId, commentId).toPromise();
  }

  async getDefaultTags() {
    return await this.commentsClient.getAllCommentTags().toPromise();
  }

  async getDiscussionTags(taskId: string, tagName: string) {
    return await this.commentsClient.getDiscussionTags(taskId, tagName).toPromise();
  }

  async filterCommentsByTag(taskId: string, tagName: string) {
    return await this.commentsClient.filterCommentsByTag(taskId, tagName).toPromise();
  }
}
