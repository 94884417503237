import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommentService } from 'src/app/services/comment.service';
import { UserService } from 'src/app/services/user.service';
import { DiscussionDto, TaskSubscribersDto, Comment, BooleanTaskDto, TasksStatus, CommentDto } from 'src/app/web-api-client';
import { faTrashCan, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { ActivatedRoute } from '@angular/router';
import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TagReplyParameter } from 'src/app/models/TagReplyParameter';
import { CommentFormDto } from 'src/app/models/CommentFormDto';
import { AddCommentDto } from 'src/app/models/AddCommentDto';
import { ProcessIds } from 'src/app/models/enums/processIds';
import { UserRoles } from 'src/app/models/enums/user-roles';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})

export class CommentListComponent implements OnInit {
  // @Input() process: string;
  @Input() taskId: string = this.activatedroute.snapshot.paramMap.get("id");
  @Input() status: TasksStatus;
  @Input() customSubscriberProvider: string = "";
  @Input() networkMemberId: string;
  @Input() networkMemberName: string;
  @Input() clientName: string;
  @Input() clientNameList: string[];
  @Input() country: string;
  @Input() periodEnd: string;
  @Input() illustrationRequestSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;

  @Input() service: any;
  @Input() isTagSystemApplicable: boolean = true;

  @Output() commentIdReply = new EventEmitter<string[]>();

  replyTagsSubject: Subject<TagReplyParameter[]> = new Subject<TagReplyParameter[]>();
  tagSelected: Subject<string> = new Subject<string>();
  quillEditorSetFocus: Subject<void> = new Subject();
  toggleShowMoreSubject: Subject<boolean> = new Subject();

  approval: BooleanTaskDto;
  lastExportedDate: Date;
  userId: string;
  dateTime = new Date()
  discussion: DiscussionDto;
  comments: Comment[] = [];
  commentId: number = 0;
  faTrashCan = faTrashCan;
  faCircleXmark = faCircleXmark;
  isNetworkMember: boolean;
  isClient: boolean;
  IsCollaborator: boolean;
  searchResult: [];
  replyTags: string[] = [];
  selectedTag: string = "";
  processId: string;
  filteredComments: Comment[] = [];
  isAllToggleShowMoreComplete: boolean = false;
  loading: boolean = false;

  replyTagsSubscription: Subscription;
  allToggleShowMoreSubscription: Subscription;
  allToggleShowMoreObservable: Observable<boolean>;

  taskSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;

  @ViewChild('discussionTable') discussionTable!: ElementRef;
  @ViewChild('searchTagField', { static: true }) searchTagField: ElementRef;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(private commentService: CommentService, private userService: UserService, private activatedroute: ActivatedRoute, private booleanTaskService: BooleanTaskService) { }

  ngOnInit() {
    this.processId = this.commentService.task.processId;
    this.comments = this.commentService.comments;
    this.filteredComments = this.comments;
    this.discussion = this.commentService.discussion;

    // Wait for the observable to complete and update isAllToggleShowMoreComplete
    this.allToggleShowMoreObservable = this.toggleShowMoreSubject.asObservable();
    this.getSubscibers()
    this.allToggleShowMoreSubscription = this.allToggleShowMoreObservable.subscribe(toggle => { this.exportDiscussionAsImage(toggle); });
  }

  // Load subscribers from the service
  async getSubscibers() {
    this.userId = await this.userService.getCurrentUserId();

    try {
      if (this.customSubscriberProvider.trim() !== "") {
        this.taskSubscribers = await this.commentService.getIllustrationTaskSubscriber(this.taskId,
          this.illustrationRequestSubscribers.clientSubscribers,
          this.illustrationRequestSubscribers.insuropeSubscribers,
          this.illustrationRequestSubscribers.networkMemberSubscribers
        );
      }
      else {
        this.taskSubscribers = this.commentService.taskSubscribers;
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  // Add a comment at the top of the comments array
  async addComment(response: CommentFormDto) {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    this.isClient = await this.userService.isUserInRole(UserRoles.Client);
    this.IsCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    var CommentParameter: AddCommentDto = {
      taskId: this.taskId,
      content: response.content,
      tags: response.commentTags,
      customSubscriberProvider: this.customSubscriberProvider,
      processName: this.processId,
      clientName: this.clientName,
      networkMemberName: this.networkMemberName,
      files: response.attachments,
      country: this.country,
      periodEnd: this.periodEnd,
      commentType: response.commentType,
      isDeleted: false,
      isEdited: false,
      notificationFrequency: response.notificationFrequency,
      notificationType: response.notificationType
    }

    var comment = await this.commentService.addComment(CommentParameter).then();

    if (comment != undefined && !(this.processId == ProcessIds.accountInputs || this.processId == ProcessIds.annualReport || this.processId == ProcessIds.illustrations)) {
      if ((this.isNetworkMember || this.isClient) && this.status == TasksStatus.Open) {
        await this.service.changeTaskStatus(this.taskId, TasksStatus.InProgress);
      }

      if (this.IsCollaborator && this.status == TasksStatus.InProgress) {
        await this.service.changeTaskStatus(this.taskId, TasksStatus.Open);
      }
    }
  }

  // export the discussion
  async exportDiscussion() {
    this.loading = true;
    this.toggleShowMoreSubject.next(true);
  }

  async exportDiscussionAsImage(toggle: boolean) {
    let div = this.discussionTable.nativeElement;
    var img: any;
    var filename: string;
    var newImage: any;

    domtoimage.toPng(div, { bgcolor: '#eff4f8' })
      .then((dataUrl) => {
        img = new Image();
        img.src = dataUrl;
        newImage = img.src;

        img.onload = async () => {
          if (toggle) {
            var pdfWidth = img.width + 20;
            var pdfHeight = img.height + 20;

            var doc: jsPDF = new jsPDF(pdfWidth > pdfHeight ? 'l' : 'p', 'px', [pdfWidth, pdfHeight]);
            doc.addImage(newImage, 'PNG', 10, 10, img.width, img.height);

            filename = 'discussion-' + this.processId + "-" + this.clientName + '.pdf';
            doc.save(filename);

            await this.commentService.editDiscussion(this.taskId, new Date());
            this.discussion.lastExported = new Date();

            this.allToggleShowMoreSubscription.unsubscribe();
            this.toggleShowMoreSubject.next(false);
            this.loading = false;
          }
        };
      })
      .catch(function (error) {
        throw new Error('Error exporting discussion. Error message: ' + error.message);
      });
  }

  copySubscriberEmail(val: string) {
    navigator.clipboard.writeText(val);
  }

  getNetworkMemberId() {
    return this.networkMemberId;
  }

  async addSubscriber(event: { id: string; }) {
    var sub = await this.commentService.addSubscriber(this.taskId, event.id);
    this.taskSubscribers = sub;
  }

  async deleteSubscriber(taskSubscribers: TaskSubscribersDto) {
    var subs = await this.commentService.deleteSubscriber(this.taskId, taskSubscribers.id);
    this.taskSubscribers = subs;
  }

  isCommentDeletable(comment: Comment) {
    var limitDate = comment.messageSent.getDate() + 1;
    var deleteComment = true;

    if (comment.userId == this.userId) {
      deleteComment = false;
      if (comment.attachments?.length > 0 && limitDate < this.dateTime.getDate()) {
        deleteComment = true;
      }
    }
    return deleteComment;
  }

  async loadTagByName(tagName: string) {
    if (tagName == '') {
      this.filteredComments = this.comments;
    } else {
      this.filteredComments = this.comments.filter(comment => comment.tags.some((e) => e.tagName.toLowerCase().startsWith(tagName.toLowerCase())))
      this.searchResult = [];
      this.selectedTag = tagName;
    }
  }

  async clearSearch() {
    this.searchResult = [];
    this.filteredComments = this.comments;
    this.searchTagField.nativeElement.value = "";
  }

  updateCommentList(comments: CommentDto[]) {
    this.filteredComments = comments;
  }

  updateSelectedTag(tag: string) {
    this.tagSelected.next(tag);
  }

  setQuillFocusEvent() {
    this.quillEditorSetFocus.next();
  }

  onNgDestroy() {
    this.replyTagsSubscription.unsubscribe();
    this.allToggleShowMoreSubscription.unsubscribe();
  }
}
