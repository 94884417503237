import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformativeService } from 'src/app/services/informative.service';
import { TasksStatus } from 'src/app/web-api-client';

@Component({
  selector: 'app-payments-detail-page',
  templateUrl: './payments-detail-page.component.html',
  styleUrls: ['./payments-detail-page.component.scss']
})
export class PaymentsDetailPageComponent implements OnInit {

  // hold the taskId
  taskId: string;
  clientName:string;
  metaData: string[] = [];
  networkMemberId: string;
  networkMemberName: string;
  status:TasksStatus;

  // contructor
  constructor(private activatedroute: ActivatedRoute, public informativeTaskService: InformativeService) { }

  // oninit method
  async ngOnInit() {
    // take the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    var informative = await this.informativeTaskService.getInformative(this.taskId);
    this.metaData = JSON.parse(informative.metaData);
    this.networkMemberId  = informative.networkMemberId;
    this.networkMemberName = informative.networkMember.accountName;
    this.clientName =informative.title;
    this.status = informative.status.value;
  }

}
