<div *ngIf="informative">
  <section class="sub-header">
    <!-- Show the back btn -->
    <button class="btn btn-back" (click)="location.back()">Back</button>
     <!-- <=12 days since start date or date mailed = green
          >=13 and <=30 => orange and weight of 5 = Orange
          >31 working days = red and weight of 0 = red
          {{ displayDate <= initDate + 12 jours ? 'green' : displayDate >= initDate + 13 && displayDate <= 30 ? 'orange' : 'red' }}
    -->
    <h2 class="{{ (dateTime.getDate() <= informative?.displayDate.getDate() + 12) ? '' : (dateTime.getDate() >= informative?.displayDate.getDate() + 13) &&  
      (dateTime.getDate() <= informative?.displayDate.getDate() + 30) ? 'midOverdue' : 'overdue' }}">{{ informative?.title }}</h2>
    <span class="ms-auto"><p>{{commonService.getLastModifiedDate([informative])}}</p></span>
  </section>

  
  <section class="card mb-3 upload-box">

    <div style="display: inline-block;" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg" aria-controls="card-body" id="collapse-header">
      <strong *ngIf="informative?.networkMember?.accountName && this.gfg" id="networkMemberCollapse">{{ informative?.networkMember?.accountName}}</strong><span class="ms-1" *ngIf=" this.gfg" >(Click for more details...)</span>
      <strong *ngIf="!informative?.networkMember?.accountName && this.gfg" class="badge badge-danger">Network Member unknown</strong>
      <div class="expand"><fa-icon [icon]="this.gfg ? this.faChevronDown : this.faChevronUp"></fa-icon></div>
   </div>

    <div class="card-body" #collapse="ngbCollapse" 
    [(ngbCollapse)]="gfg">
      <div class="line-of-2">

        <div class="col-left">

          <div *ngIf="displaySubTitle" class="mb-3">
            <h2>{{ informative?.subTitle }}</h2>
          </div>

          <div class="mb-4">
            <p *ngIf="!informative?.content">no content</p>
            <p *ngIf="informative?.content">{{ informative?.content }}</p>
          </div>

          <div *ngIf="!isNetworkMember" class="mb-3">
            <h3>Network Member</h3>
            <span id="networkMember">{{informative?.networkMember?.accountName}}</span>
          </div>

          <div class="mb-4" *ngIf="getPayToStatus()" [ngClass]="getPayToStatus()?.class">
            {{ getPayToStatus()?.text }}
          </div>
        </div>

        <div>

          <div class="col-right box align-self-start mb-3 status-box" [ngClass]="{'pay-to-present': informative?.payTo}">
  
            <div class="mb-3 flex-container">
              <div class="flex-item">
                <h3>Due Date</h3>
                <span>{{ informative?.displayDate | date }}</span>
              </div>
              <div class="flex-item" *ngIf="informative?.payTo">
                <h3>Payment To</h3>
                <span class="pay-to-text">{{ this.commonService.getPayToText(informative?.payTo) }}</span>
              </div>
            </div>
            
            <div>
              <h3>Status</h3>
              <span class="status {{statusText | lowercase}}">{{statusText}}</span>
            </div>
          </div>

          <app-attachment-list [attachments]="informative?.attachments" [adminDbAttachments]="informative?.adminDbAttachments" [commentAttachments]="commentAttachments"
          [service]="informativeService"></app-attachment-list>
          
        </div>

      </div>
    </div>
  
  </section>
</div>

<div class="text-center" *ngIf="!informative && loadingTask">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
