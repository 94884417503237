<section class="filter-labelled">
    <label for="networkMemberInputId" class="ps-3 fs-5">Tag</label>
    <div class="d-flex filter-box custom-filter">

        <!--<fa-icon [icon]="faFilter"></fa-icon>-->
        <div class="input-group">
            <input type="text" placeholder="Search" class="form-control" #searchField id="tagInputId">
            <button type="button" class="btn-close ms-1 mt-2" aria-label="Close" (click)="clearSearch()" *ngIf="searchField.value">
                <span aria-hidden="true"></span>
            </button>
        </div>

        <div *ngIf="searchingTags" class="mt-2 searching-spinner">
            <div class="spinner-border spinner-border-sm mr-1" role="status">
            </div>
            <span>Searching...</span>
        </div>
    </div>
</section>