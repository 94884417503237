import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentService } from 'src/app/services/comment.service';
import { InformativeService } from 'src/app/services/informative.service';
import { TasksStatus } from 'src/app/web-api-client';

@Component({
  selector: 'app-payments-detail-page',
  templateUrl: './payments-detail-page.component.html',
  styleUrls: ['./payments-detail-page.component.scss']
})
export class PaymentsDetailPageComponent implements OnInit {

  // hold the taskId
  taskId: string;
  clientName: string;
  metaData: string[] = [];
  networkMemberId: string;
  networkMemberName: string;
  status: TasksStatus;
  isLoading: boolean = false;

  // contructor
  constructor(private activatedroute: ActivatedRoute, public informativeTaskService: InformativeService, private commentService: CommentService) { }

  // oninit method
  async ngOnInit() {
    this.isLoading = true;
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    var informative = await this.informativeTaskService.getInformative(this.taskId);
    this.commentService.task = informative;
    this.metaData = JSON.parse(informative.metaData);
    this.networkMemberId = informative.networkMemberId;
    this.networkMemberName = informative.networkMember.accountName;
    this.clientName = informative.title;
    this.status = informative.status.value;

    this.informativeTaskService.activeTask = informative;

    this.commentService.task = informative;
    this.commentService.discussion = await this.commentService.getDiscussionByTaskId(this.taskId);
    this.commentService.taskSubscribers = await this.commentService.getTaskSubescribersByDiscussionId(this.commentService.discussion.id);
    this.isLoading = false;
  }
}
