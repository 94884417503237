import { Injectable } from '@angular/core';
import { AttachmentDTO, CommentDto, CommentsClient, CommentTagDto, DiscussionDto, TaskSubscribersDto, TaskUserSusbscriptionDto } from '../web-api-client';
import { Subject } from 'rxjs';
import { AddCommentDto } from '../models/AddCommentDto';
import { TagReplyParameter } from '../models/TagReplyParameter';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  setClientReplyTagValue = new Subject<TagReplyParameter>();
  getClientReplyTagValue = this.setClientReplyTagValue.asObservable();
  setCommentReplyTagsList = new Subject<TagReplyParameter[]>();
  getCommentReplyTagsList = this.setCommentReplyTagsList.asObservable();

  deleteCommentEvent = new Subject<string>();
  addCommentAttachmentEvent = new Subject<AttachmentDTO[]>();

  discussion: DiscussionDto;
  comments: CommentDto[] = []
  taskSubscribers: TaskSubscribersDto
  task: any;
  defaultTags: CommentTagDto[];

  constructor(private commentsClient: CommentsClient) {
  }

  async getDiscussionByTaskId(taskId: string) {
    this.discussion = await this.commentsClient.get(taskId).toPromise();
    this.comments = this.discussion.comments.reverse().filter(x => x.isDeleted == false);
    return this.discussion;
  }

  async getTaskSubescribersByDiscussionId(discussionId: string) {
    return await this.commentsClient.getDiscussionTaskSubscribers(discussionId).toPromise();
  }

  async getIllustrationTaskSubscriber(discussionId: string, clientSubscribers: TaskUserSusbscriptionDto[], insuropeSubscribers: TaskUserSusbscriptionDto[], networkMemberSubscribers: TaskUserSusbscriptionDto[]) {
    return await this.commentsClient.getIllustrationTaskSubscribers(discussionId, clientSubscribers, insuropeSubscribers, networkMemberSubscribers).toPromise();
  }

  async addComment(commentParams: AddCommentDto) {
    var comment = await this.commentsClient.create(commentParams.taskId,
      commentParams.customSubscriberProvider,
      commentParams.processName,
      commentParams.clientName,
      commentParams.networkMemberName,
      commentParams.country,
      commentParams.periodEnd,
      commentParams.files,
      commentParams.content,
      commentParams.tags,
      commentParams.isDeleted,
      commentParams.isEdited,
      commentParams.commentType,
      commentParams.notificationFrequency,
      commentParams.notificationType).toPromise()

    if (comment) {
      this.taskSubscribers = await this.addCurrentUserToSubscriber(commentParams.taskId);
    }

    if (comment.attachments?.length > 0) {
      this.addCommentAttachmentEvent.next(comment.attachments);
    }

    this.comments.splice(0, 0, comment);
    return comment;
  }

  async downloadFile(taskId: string, discussionId: string, fileId: string) {
    return await this.commentsClient.downloadFile(taskId, discussionId, fileId).toPromise();
  }

  async deleteComment(taskId: string, commentId: string) {
    await this.commentsClient.delete(taskId, commentId).toPromise();
    var index = this.comments.findIndex(x => x.id == commentId);
    this.comments.splice(index, 1);
    this.deleteCommentEvent.next(commentId);
  }

  async editDiscussion(taskId: string, lastExportedDate: Date) {
    return await this.commentsClient.update(taskId, lastExportedDate).toPromise();
  }

  async addCurrentUserToSubscriber(taskId: string) {
    return await this.commentsClient.addCurrentUserToSubscribers(taskId).toPromise();
  }

  async addSubscriber(taskId: string, subscriberId: string) {
    return await this.commentsClient.addSubscriber(taskId, subscriberId).toPromise();
  }

  async deleteSubscriber(taskId: string, subscriberId: string) {
    return await this.commentsClient.deleteSubscriber(taskId, subscriberId).toPromise();
  }

  async getAllAttachments(taskId: string) {
    return await this.commentsClient.getAllAttachments(taskId).toPromise();
  }

  async addCommentTag(tagNames: string[], taskId: string, commentId: string) {
    return await this.commentsClient.addCommentTags(tagNames, taskId, commentId).toPromise();
  }

  async getCommentTags(taskId: string, commentId: string) {
    return await this.commentsClient.getCommentTags(taskId, commentId).toPromise();
  }

  async deleteCommentTags(tagId: string, taskId: string, commentId: string) {
    return await this.commentsClient.deleteCommentTags(tagId, taskId, commentId).toPromise();
  }

  async getDefaultTags() {
    if (!this.defaultTags) {
      this.defaultTags = await this.commentsClient.getDefaultTags().toPromise();
      return this.defaultTags;
    }
  }

  async upsertDefaultTag(tagName: string) {
    return await this.commentsClient.upsertDefaultTag(tagName).toPromise();
  }

  async getDiscussionTags(taskId: string, tagName: string) {
    return await this.commentsClient.getDiscussionTags(taskId, tagName).toPromise();
  }

  async filterCommentsByTag(taskId: string, tagName: string) {
    return await this.commentsClient.filterCommentsByTag(taskId, tagName).toPromise();
  }
}
