import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { fromEvent, map, filter, debounceTime, distinctUntilChanged, Subscription, Observable } from 'rxjs';
import { CommentService } from 'src/app/services/comment.service';
import { CommentDto, CommentTagDto } from 'src/app/web-api-client';

@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.scss']
})
export class TagFilterComponent implements OnInit {
  searchingTags: boolean = false;
  filteredTags: CommentTagDto[] = [];
  clickedOnTag: boolean = false;

  searchEventUsbscription: Subscription;
  tagSelectedSubscription: Subscription;

  @Input() discussionId: string;
  @Input() tagSelected: Observable<string> = new Observable<string>();
  @Output() filteredCommentsEventEmitter = new EventEmitter<CommentDto[]>();
  @ViewChild('searchField', { static: true }) searchInputField: ElementRef;

  constructor(public CommentService: CommentService) { }

  async ngOnInit() {
    this.searchInputField.nativeElement.value = "";
    this.tagSelectedSubscription = this.tagSelected.subscribe((tags: string) => { this.fetchTags(tags); });

    this.searchEventUsbscription = fromEvent(this.searchInputField.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => { return event.target.value; }),
      // if character length greater then 1
      filter(res => res.length > 2),
      // Time in milliseconds between key events
      debounceTime(50),
      // If previous query is diffent from current   
      distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.fetchTags(text);
    });

    fromEvent(this.searchInputField.nativeElement, 'keydown').pipe(
      // get value
      map((event: any) => { return event.target.value; }), filter(res => res.length < 1),)
      .subscribe(() => {
        this.clearSearch();
      });
  }

  async fetchTags(tagName: string) {
    this.clickedOnTag = false;
    this.searchingTags = true;
    this.searchInputField.nativeElement.value = tagName;

    if (tagName === '') {
      return this.filteredTags = [];
    }

    this.searchingTags = true;
    var filteredComments = await this.CommentService.filterCommentsByTag(this.discussionId, tagName);
    this.filteredCommentsEventEmitter.emit(filteredComments.sort((a, b) => b.messageSent.getTime() - a.messageSent.getTime()));
    this.searchingTags = false;
  }

  async clearSearch() {
    this.filteredTags = [];
    this.searchInputField.nativeElement.value = "";
    this.searchingTags = true;
    var filteredComments = await this.CommentService.filterCommentsByTag(this.discussionId, "");
    this.filteredCommentsEventEmitter.emit(filteredComments.sort((a, b) => b.messageSent.getTime() - a.messageSent.getTime()));
    this.searchingTags = false;
  }

  ngOnDestroy() {
    this.searchEventUsbscription.unsubscribe();
    this.tagSelectedSubscription.unsubscribe();
  }
}
