<div class="box p-0">
    <!-- Subscribers section -->
    <div class="mt-3 ms-3 d-inline-block">
        <h3 class="mb-4">Discussion</h3>
        <app-users-search *ngIf="networkMemberId" [networkMemberId]="networkMemberId" (subscriberId)="addSubscriber($event)" class="mb-2 d-flex "></app-users-search>
        <div *ngIf="taskSubscribers?.insuropeSubscribers?.length > 0" class="mb-2 d-flex flex-column">
            <div class="list-of-n">
                <ng-container *ngFor="let taskUserSusbscription of taskSubscribers.insuropeSubscribers">
                    <div *ngIf="!taskUserSusbscription.isDeleted" (click)="copySubscriberEmail(taskUserSusbscription.preferredEmail)" class="subscriber-mail btn-secondary">
                        <span class="adress-mail">{{ taskUserSusbscription.preferredEmail }}</span>
                        <span (click)="deleteSubscriber(taskUserSusbscription)" class="delete-subscriber">
                            <fa-icon [icon]="faCircleXmark" size="lg"></fa-icon>
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="mb-4 d-flex flex-column" *ngIf="taskSubscribers?.networkMemberSubscribers?.length > 0">
            <div class="list-of-n">
                <ng-container *ngFor="let taskUserSusbscription of taskSubscribers.networkMemberSubscribers">
                    <div *ngIf="!taskUserSusbscription.isDeleted" (click)="copySubscriberEmail(taskUserSusbscription.preferredEmail)" class="subscriber-mail btn-third">
                        <span class="adress-mail">{{ taskUserSusbscription.preferredEmail }}</span>
                        <span (click)="deleteSubscriber(taskUserSusbscription)" class="delete-subscriber">
                            <fa-icon [icon]="faCircleXmark" size="lg"></fa-icon>
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="mb-4 d-flex" *ngIf="taskSubscribers?.insuropeSubscribers?.length == 0 || taskSubscribers?.insuropeSubscribers == null">
            <span>
                <p>No subscribers for this task</p>
            </span>
        </div>
    </div>

    <!-- Export discussion -->
    <div class="float-end">
        <span *ngIf="discussion?.lastExported" class="m-3 lastExport">Last export: {{ discussion.lastExported | date}}</span>
        <button [disabled]="comments.length == 0" class="btn btn-primary m-3 float-end" (click)="exportDiscussion()">Export</button>
    </div>

    <!-- Loading spinner -->
    <div class="text-center" *ngIf="loading">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <!-- Show the form to sent a comment -->
    <app-comment-form (commentEvent)="addComment($event)" [isTagSystemApplicable]="isTagSystemApplicable"></app-comment-form>
    <br>
    <!--Show tags filter -->
    <section class="filter-labelled d-flex cta-container" *ngIf="isTagSystemApplicable">
        <div class="">
            <div class="row">
                <div class="col-md-2">
                    <app-tag-filter #searchTagField id="searchTagField" [discussionId]="discussion?.id" (filteredCommentsEventEmitter)="updateCommentList($event)" [tagSelected]="tagSelected.asObservable()"></app-tag-filter>
                </div>
            </div>
        </div>
    </section>

    <!-- Show comments -->
    <ul class="p-3 pt-1 comment-list" id="discussionTable" #discussionTable>
        <li *ngFor="let comment of filteredComments">
            <app-comment-item [comment]="comment" [userId]="userId" [discussionId]="discussion?.id" [isTagSystemApplicable]="isTagSystemApplicable" class="d-flex" (filterByTagEvent)="updateSelectedTag($event)"
                [clientNameList]="clientNameList" [toggleShowMoreObservable]="toggleShowMoreSubject.asObservable()"></app-comment-item>
        </li>
    </ul>
</div>