import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoles } from 'src/app/constants/user-roles';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { ClientsService } from 'src/app/services/clients.service';
import { UserService } from 'src/app/services/user.service';
import { AccountDto, CrmAttachmentDto } from 'src/app/web-api-client';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  client: AccountDto;

  isNetworkMember: boolean = false;
  isClient: boolean = false;
  showDashboard: boolean = true;
  clientName:string="";
  // hold the attachments
  importantFiles: CrmAttachmentDto[] = [];
  importantFilesSizes: string[] = [];
  loadingImportantFiles: boolean = false;
  isInternal: boolean = false;
  latestAnnualReportId: string;
  latestCompositionReportId: string;
  latestDividendPaymentLetterId: string;

  constructor(private accountService: AccountsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private clientsService: ClientsService){ }

  async ngOnInit() {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    this.isClient = await this.userService.isUserInRole(UserRoles.Client);
    this.isInternal = await this.userService.isUserInRole(UserRoles.Collaborator);
    this.client = JSON.parse(localStorage.getItem("client"))
    this.activatedRoute.queryParamMap.subscribe(async (params) => {
      if (params.has('networkMemberId')) {
        await this.getClientSearchResponse(await this.accountService.getAccountById(params?.get('networkMemberId')));
      }
    });

    if(this.isClient || this.isInternal ) {
      let clientId = (await this.userService.getCurrentUser()).clientId;
      this.client = await this.accountService.getAccountById(clientId);
    }

  }

  async getClientSearchResponse(response : AccountDto){
    this.client = null;
    this.latestAnnualReportId = "";
    this.latestCompositionReportId = "";
    this.latestDividendPaymentLetterId = "";

    this.client = response;
    localStorage.setItem('client', response.toJSON());

    this.router.navigate([], 
    {
      relativeTo: this.activatedRoute,
      queryParams: { clientId: this.client.externalAccountNumber },
      queryParamsHandling: 'merge',
    });
    
    this.loadingImportantFiles = true;
    this.latestAnnualReportId = (await this.clientsService.getClientImportantDocuments(this.client.id, "LAR"))?.id;
    this.latestCompositionReportId = (await this.clientsService.getClientImportantDocuments(this.client.id, "LCR"))?.id;
    this.latestDividendPaymentLetterId = (await this.clientsService.getClientImportantDocuments(this.client.id, "LSDPL"))?.id;
    this.loadingImportantFiles = false;
  }

  changeClientViewActiveTab(tabName: string) {
    this.showDashboard = (tabName == "info");
  }

}


